<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title v-if="servicePoint && servicePoint.address">
          {{ servicePoint.address.country }}, {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
          {{ servicePoint.address.building_number }}
        </v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка торгівельної точки</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" @change="onTabChange" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in filteredTabsWithRoles" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in filteredTabsWithRoles" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isServicePointExist && isLoaded"
              :is="item.component"
              :currentItem="servicePoint"
              :isDataSaved="isDataSaved"
              :currentTab="currentTab"
              :currentTabName="currentTabName"
              :editCard="editCard"
              @saveData="saveData"
              @changes="editCard = true"
              @showEditButtons="(value) => (showEditButtons = value)"
              @terminalDisconnected="initialize"
            ></component>
            <v-container v-show="isPointTabActive && showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn v-if="!editCard && !isContactsTabActive" @click="editCard = true" class="mr-2" small>Редагувати</v-btn>
                <v-btn v-if="editCard" @click="editCard = false" class="mr-2" small>
                  {{ isContactsTabActive ? 'Скасувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)" class="mt-5" flat>
      <v-toolbar tile elevation="1">
        <v-tabs v-model="logTab">
          <v-tab
            v-for="tab in LOG_TABS"
            :key="tab.name"
            @click="$router.replace({ query: { logTab: tab.name } })"
            :tab-value="tab.name"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <Logs :instanceId="+currentServicePointId" />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
// import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import { SERVICE_POINTS_SCHEMA } from '@/const/apiSchemas'
import BackButton from '@/components/common/BackButton.vue'
import SetLogTabs from '@/mixins/setLogTabs'
import { ROLE } from '@/const/roles.enum'
import GetTabsWithRoles from '@/mixins/getTabsWithRoles'

export default {
  name: 'ServicePointCard',

  components: {
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    Online: () => import('@/components/administration/service_point/Online'),
    Point: () => import('@/components/administration/service_point/Point'),
    Devices: () => import('@/components/administration/service_point/Devices'),
    Goods: () => import('@/components/administration/terminal/Goods'),
    Finance: () => import('@/components/administration/service_point/Finance'),
    Marketing: () => import('@/components/administration/service_point/Marketing'),
    Maintenance: () => import('@/components/administration/service_point/Maintenance'),
    Support: () => import('@/components/administration/service_point/Support'),
    Logs: () => import('@/components/common/Logs'),
  },

  mixins: [ConvertStatusesTypes, SetLogTabs, GetTabsWithRoles],

  data() {
    return {
      showModal: false,
      showEditButtons: true,
      currentTab: 1,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      defaultItem: { ...SERVICE_POINTS_SCHEMA },
      tabs: [
        {
          name: 'online',
          title: 'online',
          component: 'Online',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
        {
          name: 'point',
          title: 'Точка',
          component: 'Point',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
        {
          name: 'devices',
          title: 'Пристрої',
          component: 'Devices',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
        {
          name: 'goods',
          title: 'Товари',
          component: 'Goods',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
        {
          name: 'finance',
          title: 'Фінанси',
          component: 'Finance',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
        },
        {
          name: 'marketing',
          title: 'Маркетинг',
          component: 'Marketing',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.SUPPORT],
        },
        {
          name: 'maintenance',
          title: 'Обслуговування',
          component: 'Maintenance',
          roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.TECHNICIAN, ROLE.SUPPORT],
        },
        // {
        //   name: 'support',
        //   title: 'Підтримка',
        //   component: 'Support',
        //   roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        // },
      ],
    }
  },

  computed: {
    ...mapState('servicePoints', ['servicePoint']),
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentServicePointId() {
      return this.$route.params.servicePointId
    },
    isServicePointExist() {
      return !!Object.entries(this.servicePoint).length
    },
    logsFilter() {
      return this.$route.query
    },
    isContactsTabActive() {
      return this.currentTabName === 'contacts'
    },
    currentTabName() {
      return this.filteredTabsWithRoles[this.currentTab].name
    },
    isPointTabActive() {
      return this.currentTabName === 'point'
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_SERVICE_POINT({})
    this.SET_ADDRESS({})
  },

  methods: {
    ...mapActions('servicePoints', ['loadSelectedServicePoint', 'updateSelectedServicePoint']),
    ...mapActions('addresses', ['loadAddress']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('servicePoints', ['SET_SELECTED_SERVICE_POINT']),
    ...mapMutations('addresses', ['SET_ADDRESS']),

    async initialize() {
      this.isLoaded = false
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          servicePointId: this.currentServicePointId,
        }
        await this.loadSelectedServicePoint(payload)
        if (this.servicePoint.address) {
          const addressPayload = {
            type: 'servicePoint',
            companyId: this.currentCompanyId,
            addressId: this.servicePoint.address.id,
            servicePointId: this.servicePoint.id,
          }
          await this.loadAddress(addressPayload)
        }
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
      this.logTab = this.$route.query?.logTab || this.LOG_TABS[0].name
    },
    async saveData(updatedServicePoint) {
      // this.isLoaded = false
      // updatedServicePoint = mergeObjectsDeep(this.defaultItem, updatedServicePoint)
      const payloadForUpdate = {
        updatedServicePoint,
        companyId: this.currentCompanyId,
        servicePointId: this.currentServicePointId,
      }
      /*const payloadForLoad = {
        companyId: this.currentCompanyId,
        servicePointId: this.currentServicePointId,
      }*/
      this.showModal = false
      await this.updateSelectedServicePoint(payloadForUpdate)
      // await this.forceRerender()
      this.isDataSaved = false
      this.editCard = false
      // this.isLoaded = true
    },
    onTabChange() {
      this.$router.replace({ hash: `tab-${this.currentTab}` })
    },
  },
}
</script>

<style scoped lang="scss"></style>
